<ion-grid class="multi-stores">
  <ion-row>
    <ion-col class="multi-stores-col" size="4" *ngFor="let restaurant of restaurants">
      <ion-card class="multi-stores-card" (click)="restaurantSelected.emit(restaurant.merchantStoreId)">
        <ion-thumbnail class="multi-stores-card-thumbnail" *ngIf="restaurant.imageUrl">
          <ion-img class="multi-stores-card-img" [src]="baseImageUrl + restaurant.imageUrl"></ion-img>
        </ion-thumbnail>
        <ion-card-header class="multi-stores-card-header">
          <ion-card-title class="multi-stores-card-title lh-16">{{ restaurant.name }}</ion-card-title>
        </ion-card-header>
      </ion-card>
    </ion-col>
  </ion-row>
</ion-grid>
