export const multiStoresMerchantStoreIdsProd = ['036a8066-5da1-4e98-af8b-aaaab570e286'];
export const multiStoresMerchantStoreIdsStage = ['2824771c-e3a7-4691-a042-b30077f22e60'];
export const multiStoresMerchantStoreIdsDev = ['036a8066-5da1-4e98-af8b-aaaab570e286'];

export const tokiaSquareRestaurantsProd = [
  { name: 'Japenese Unit', imageUrl: 'tokia-square/japanese-unit.png', merchantStoreId: '0671fbd0-c73c-4f8f-aa23-ad5996714b8e' },
  { name: 'Chinese Unit', imageUrl: 'tokia-square/chinese-unit.png', merchantStoreId: '188ac370-a158-4b63-8552-f226330607b8' },
  { name: 'Central Unit', imageUrl: 'tokia-square/central-unit.png', merchantStoreId: '623d4919-7baa-4f69-9d57-b3f7c88b88bd' },
  { name: 'Patisserie Unit', imageUrl: 'tokia-square/patisserie-unit.png', merchantStoreId: '0671fbd0-c73c-4f8f-aa23-ad5996714b8e' },
  { name: 'Singapore Unit', imageUrl: 'tokia-square/singapore-unit.png', merchantStoreId: '0671fbd0-c73c-4f8f-aa23-ad5996714b8e' },
  { name: 'Korean Unit', imageUrl: 'tokia-square/korean-unit.png', merchantStoreId: '0671fbd0-c73c-4f8f-aa23-ad5996714b8e' },
  { name: 'Indian Unit', imageUrl: 'tokia-square/indian-unit.png', merchantStoreId: '0671fbd0-c73c-4f8f-aa23-ad5996714b8e' },
  { name: 'Thai Unit', imageUrl: 'tokia-square/thai-unit.png', merchantStoreId: '0671fbd0-c73c-4f8f-aa23-ad5996714b8e' },
  { name: 'Vietnamese Unit', imageUrl: 'tokia-square/vietnamese-unit.png', merchantStoreId: '0671fbd0-c73c-4f8f-aa23-ad5996714b8e' },
];

export const tokiaSquareRestaurantsStage = [
  { name: 'Japenese Unit', imageUrl: 'tokia-square/japanese-unit.png', merchantStoreId: 'fd9344fe-8b45-455e-92fc-6c17dab812ca' },
  { name: 'Chinese Unit', imageUrl: 'tokia-square/chinese-unit.png', merchantStoreId: 'aea05ac8-2ebb-4284-97c0-35e9fb5f39fe' },
  { name: 'Central Unit', imageUrl: 'tokia-square/central-unit.png', merchantStoreId: '5cef5476-584e-4436-96fc-22300d10cef3' },
  { name: 'Patisserie Unit', imageUrl: 'tokia-square/patisserie-unit.png', merchantStoreId: '0671fbd0-c73c-4f8f-aa23-ad5996714b8e' },
  { name: 'Singapore Unit', imageUrl: 'tokia-square/singapore-unit.png', merchantStoreId: '0671fbd0-c73c-4f8f-aa23-ad5996714b8e' },
  { name: 'Korean Unit', imageUrl: 'tokia-square/korean-unit.png', merchantStoreId: '0671fbd0-c73c-4f8f-aa23-ad5996714b8e' },
  { name: 'Indian Unit', imageUrl: 'tokia-square/indian-unit.png', merchantStoreId: '0671fbd0-c73c-4f8f-aa23-ad5996714b8e' },
  { name: 'Thai Unit', imageUrl: 'tokia-square/thai-unit.png', merchantStoreId: '0671fbd0-c73c-4f8f-aa23-ad5996714b8e' },
  { name: 'Vietnamese Unit', imageUrl: 'tokia-square/vietnamese-unit.png', merchantStoreId: '0671fbd0-c73c-4f8f-aa23-ad5996714b8e' },
];

export const tokiaSquareRestaurantsDev = [
  { name: 'Japenese Unit', imageUrl: 'tokia-square/japanese-unit.png', merchantStoreId: '0671fbd0-c73c-4f8f-aa23-ad5996714b8e' },
  { name: 'Chinese Unit', imageUrl: 'tokia-square/chinese-unit.png', merchantStoreId: '188ac370-a158-4b63-8552-f226330607b8' },
  { name: 'Central Unit', imageUrl: 'tokia-square/central-unit.png', merchantStoreId: '623d4919-7baa-4f69-9d57-b3f7c88b88bd' },
  { name: 'Patisserie Unit', imageUrl: 'tokia-square/patisserie-unit.png', merchantStoreId: '0671fbd0-c73c-4f8f-aa23-ad5996714b8e' },
  { name: 'Singapore Unit', imageUrl: 'tokia-square/singapore-unit.png', merchantStoreId: '0671fbd0-c73c-4f8f-aa23-ad5996714b8e' },
  { name: 'Korean Unit', imageUrl: 'tokia-square/korean-unit.png', merchantStoreId: '0671fbd0-c73c-4f8f-aa23-ad5996714b8e' },
  { name: 'Indian Unit', imageUrl: 'tokia-square/indian-unit.png', merchantStoreId: '0671fbd0-c73c-4f8f-aa23-ad5996714b8e' },
  { name: 'Thai Unit', imageUrl: 'tokia-square/thai-unit.png', merchantStoreId: '0671fbd0-c73c-4f8f-aa23-ad5996714b8e' },
  { name: 'Vietnamese Unit', imageUrl: 'tokia-square/vietnamese-unit.png', merchantStoreId: '0671fbd0-c73c-4f8f-aa23-ad5996714b8e' },
];
