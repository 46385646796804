import { GooglePayEnv } from '../../types/google-pay-env.type';
import { MerchantRestaurant } from '../multistores/multi-stores-interfaces';

export interface Environment {
  name: string;
  production: boolean;
  baseUrl: string;
  baseDomainUrl: string;
  baseApiUrl: string;
  checkoutAPIConf: {
    endpoint: string;
  };
  googlePayEnvironment: GooglePayEnv;
  applePayMerchantId: string;
  multiStoreMerchantIds: string[];
  tokiaSquareRestaurants: MerchantRestaurant[];
  launchDarklyClientId: string;
  firebaseConfig?: any;
}
