import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MerchantStoreApiService } from '@libs/shared/data-access';
import { AppTheme, Environment, UserSession } from '@libs/shared/types';
import { APP_ENVIRONMENT, KodyOrderRouterUtilityService, SessionStorageService } from '@libs/shared/utilities';
import { BrandingApiService, BrandingService } from '@libs/utility-branding';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, from, of } from 'rxjs';
import { catchError, exhaustMap, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { appStateApiActions } from '../actions/app-state-api.actions';
import { appStateActions } from '../actions/app-state.actions';
import { AppStateFacadeService } from '../services/app-state-facade.service';

@Injectable()
export class AppStateEffects {
  constructor(
    private actions$: Actions,
    private merchantStoreApiService: MerchantStoreApiService,
    private appStateFacadeService: AppStateFacadeService,
    private sessionStorageService: SessionStorageService,
    private readonly brandingApiService: BrandingApiService,
    private readonly brandingService: BrandingService,
    private router: Router,
    @Inject(APP_ENVIRONMENT) private environment: Environment,
    private routerUtilityService: KodyOrderRouterUtilityService
  ) {
    this.baseApiUrl = `${this.environment.baseApiUrl}/web/`;
  }
  private baseApiUrl: string;

  getMerchantStore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appStateActions.enterApp, appStateActions.resetApp),
      switchMap(({ merchantStoreId, orderType, table }) =>
        this.merchantStoreApiService.getMerchantStore(this.baseApiUrl, merchantStoreId).pipe(
          map((store) => appStateApiActions.getMerchantStoreSuccess({ merchantStore: store, orderType, table })),
          catchError((error: HttpErrorResponse) => of(appStateApiActions.getMerchantStoreFailure({ error })))
        )
      )
    )
  );

  saveMenuPageCartToSessionStorage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(appStateActions.addItemToCart),
        concatLatestFrom(() => [
          this.appStateFacadeService.getMerchantStore(),
          this.appStateFacadeService.getTable(),
          this.appStateFacadeService.getCart(),
          this.appStateFacadeService.getClickAndCollectSlot(),
          this.appStateFacadeService.getDiscount(),
          this.appStateFacadeService.getAcknowledgedEvents(),
        ]),
        tap(([, merchantStore, table, cart, clickCollectSlot, discount, acknowledgedEvents]) => {
          const entryRoute = this.router.url;
          this.sessionStorageService.setItem<UserSession>(merchantStore.merchantStoreId, {
            entryRoute,
            cart,
            table,
            clickCollectSlot,
            discount,
            acknowledgedEvents,
          });
        })
      ),
    { dispatch: false }
  );

  // We don't want to save the entry route when editing the quantity on the payment page
  savePaymentCartToSessionStorage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(appStateActions.updateCartItemQuantity),
        withLatestFrom(this.appStateFacadeService.getMerchantStore()),
        exhaustMap(([, merchantStore]) => {
          return combineLatest([
            from(this.sessionStorageService.getItem<UserSession>(merchantStore.merchantStoreId)),
            this.appStateFacadeService.getTable(),
            this.appStateFacadeService.getCart(),
            this.appStateFacadeService.getDiscount(),
            this.appStateFacadeService.getAcknowledgedEvents(),
            of(merchantStore.merchantStoreId),
          ]).pipe(
            tap(([{ entryRoute }, table, cart, discount, acknowledgedEvents, merchantStoreId]) => {
              this.sessionStorageService.setItem<UserSession>(merchantStoreId, { entryRoute, cart, discount, table, acknowledgedEvents });
            })
          );
        })
      ),
    { dispatch: false }
  );

  getAvailableSlots$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appStateActions.getAvailableClickAndCollectSlots),
      withLatestFrom(this.appStateFacadeService.getMerchantStore()),
      filter(([, merchantStore]) => !!merchantStore),
      switchMap(([, { merchantStoreId }]) =>
        this.merchantStoreApiService.getClickCollectSlots(this.baseApiUrl, merchantStoreId).pipe(
          map((availableSlots) => appStateApiActions.getAvailableClickCollectSlotsSuccess({ availableSlots })),
          catchError((error) => of(appStateApiActions.getAvailableClickCollectSlotsFailure({ error })))
        )
      )
    )
  );

  saveClickCollectSlotToSessionStorage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(appStateActions.setClickAndCollectSlot),
        concatLatestFrom(() => this.appStateFacadeService.getMerchantStore()),
        exhaustMap(([{ slot }, { merchantStoreId }]) => {
          return combineLatest([
            of(slot),
            of(merchantStoreId),
            this.appStateFacadeService.getCart(),
            this.appStateFacadeService.getDiscount(),
            this.appStateFacadeService.getAcknowledgedEvents(),
            from(this.sessionStorageService.getItem<UserSession>(merchantStoreId)),
          ]).pipe(
            take(1),
            tap(([slot, merchantStoreId, cart, discount, acknowledgedEvents, userSession]) => {
              const entryRoute = this.routerUtilityService.isPaymentRoute() ? userSession.entryRoute : this.router.url;
              this.sessionStorageService.setItem<UserSession>(merchantStoreId, {
                cart,
                discount,
                entryRoute,
                clickCollectSlot: slot,
                acknowledgedEvents,
              });
            })
          );
        })
      ),
    { dispatch: false }
  );

  saveDiscountToSessionStorage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(appStateActions.setDiscount),
        concatLatestFrom(() => this.appStateFacadeService.getMerchantStore()),
        exhaustMap(([{ discount }, { merchantStoreId }]) => {
          return from(this.sessionStorageService.getItem<UserSession>(merchantStoreId)).pipe(
            tap((userSession) => {
              this.sessionStorageService.setItem<UserSession>(merchantStoreId, { ...userSession, discount });
            })
          );
        })
      ),
    { dispatch: false }
  );

  saveAcknowledgedEventToSessionStorage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(appStateActions.acknowledgeEvent),
        concatLatestFrom(() => this.appStateFacadeService.getMerchantStore()),
        exhaustMap(([{ acknowledgedEvent }, { merchantStoreId }]) => {
          return from(this.sessionStorageService.getItem<UserSession>(merchantStoreId)).pipe(
            map((userSession) => userSession || {}),
            tap((userSession) => {
              this.sessionStorageService.setItem<UserSession>(merchantStoreId, {
                ...userSession,
                acknowledgedEvents: (userSession?.acknowledgedEvents || []).concat(
                  (userSession?.acknowledgedEvents || []).includes(acknowledgedEvent) ? [] : acknowledgedEvent
                ),
              });
            })
          );
        })
      ),
    { dispatch: false }
  );

  saveSmsNotificationNumberToSessionStorage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(appStateActions.setSmsNotificationNumber),
        concatLatestFrom(() => this.appStateFacadeService.getMerchantStore()),
        switchMap(([{ smsNotificationNumber }, { merchantStoreId }]) => {
          return from(this.sessionStorageService.getItem<UserSession>(merchantStoreId)).pipe(
            tap((userSession) => {
              this.sessionStorageService.setItem<UserSession>(merchantStoreId, { ...userSession, smsNotificationNumber });
            })
          );
        })
      ),
    { dispatch: false }
  );

  setDefaultClickAndCollectSlot$ = createEffect(() =>
    this.actions$.pipe(
      ofType(appStateApiActions.getAvailableClickCollectSlotsSuccess),
      concatLatestFrom(() => this.appStateFacadeService.getMerchantStore()),
      switchMap(([{ availableSlots }, { merchantStoreId }]) =>
        from(this.sessionStorageService.getItem<UserSession>(merchantStoreId)).pipe(
          filter((userSession) => !this.routerUtilityService.isPaymentRoute() && !userSession),
          map(() => appStateActions.setDefaultClickAndCollectSlot({ availableSlots }))
        )
      )
    )
  );

  updateBrandingTheme$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(appStateActions.updateStoreBrandingTheme),
        switchMap(({ storeId }) => {
          return this.brandingApiService.getTheme(storeId).pipe(
            tap((theme: AppTheme) => {
              if (theme) {
                this.brandingService.setStyles(theme);
              }
            })
          );
        })
      ),
    { dispatch: false }
  );
}
