export const kodyOrderBaseRoutes = {
  root: 'store',
  table: 'table',
  multiStores: 'multi-stores',
  clickCollect: 'click-collect',
  counter: 'counter',
  payment: 'order', // TODO: Rename?
  orderConfirmed: 'order-confirmed',
  orderFailed: 'order-failed',
};
