<ion-header>
  <ion-toolbar class="change-restaurant-toolbar">
    <h2 class="change-restaurant-heading">You have unpaid items. Do you want to complete your purchase before leaving?</h2>
  </ion-toolbar>
</ion-header>

<ion-content class="change-restaurant-content">
  <p class="cart-merchant-name">{{ restaurantName }}</p>
  <ion-grid class="change-restaurant-grid">
    <ng-container *ngFor="let cartItem of cart.itemGroups; let i = index">
      <ion-row *ngIf="i < noOfCartItemsToShow" class="cart-item ion-align-items-center">
        <ion-col size="1">
          <p class="cart-item-text cart-item-quantity">{{ cartItem.quantity }}</p>
        </ion-col>
        <ion-col size="8">
          <p class="cart-item-text">{{ cartItem.item.name }}</p>
        </ion-col>
        <ion-col size="3" class="ion-text-right">
          <p class="cart-item-text">{{ cartItem.item.price | currency : '£' }}</p>
        </ion-col>
      </ion-row>
    </ng-container>
  </ion-grid>
  <p class="cart-more-items" *ngIf="cart.itemGroups.length > 2">
    {{ cart.itemGroups.length - noOfCartItemsToShow }} more {{ cart.itemGroups.length - noOfCartItemsToShow === 1 ? 'item' : 'items' }}
  </p>
</ion-content>

<ion-footer class="change-restaurant-footer">
  <ion-toolbar>
    <ion-button slot="start" fill="clear" class="clear-cart-btn" (click)="clearCart()">Clear cart</ion-button>
    <ion-button slot="end" class="pay-cart-btn" (click)="returnAndPay()">Return and Pay</ion-button>
  </ion-toolbar>
</ion-footer>
