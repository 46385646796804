<ion-header
  class="menu-header"
  id="menu-header"
  [ngStyle]="{ height: menuHeaderSizes.default + 'px', 'background-image': headerImage }"
  [ngClass]="{ 'no-header-image': !headerImage }"
>
  <ion-toolbar class="menu-header__persistent" [ngStyle]="{ height: menuHeaderSizes.shrunk + 'px' }">
    <span class="menu-header__price menu-header--show-on-shrink" slot="end" kp-object="menu_header_shrunk_total_bill">{{
      cartPrice | currency : '£'
    }}</span>

    <div class="menu-header__store-name">
      <div [kodyFitText]="storeName" maxFontSize="28" minFontSize="12" kp-object="menu_header_store_name"></div>
    </div>
    <span kp-object="menu-subtitle">
      {{ subtitle }}

      <!--  Switch between eating in / takeout  -->
      <ng-container *ngIf="orderType === OrderTypes.RESTAURANT_COUNTER">
        <span> - </span>
        <a data-test-id="toggleBtn" (click)="toggleEatingIn.emit()" class="menu-header__eating-in" kp-object="menu_header_eating_in_btn"
          >{{ !isEatingIn ? 'Eating in' : 'Takeout' }}?</a
        >
      </ng-container>

      <!--  Choose C&C slot  -->
      <a
        *ngIf="orderType === OrderTypes.CLICK_COLLECT && clickCollectSlots?.length && !storeIsClosed"
        class="menu-header__change-slot"
        (click)="selectSlot.emit()"
        data-test-id="selectSlotBtn"
        kp-object="menu_header_click_collect_slot"
      >
        {{ selectedSlot ? 'Change' : 'Choose' }} slot
      </a>

      <kody-duration
        *ngIf="orderType !== OrderTypes.CLICK_COLLECT"
        prefix=" - "
        [minutes]="waitTimeMinutes"
        kp-object="menu_header_wait_time"
      ></kody-duration>
    </span>
  </ion-toolbar>

  <div class="menu-header__info menu-header--hide-on-shrink">
    <section *ngIf="table && orderType === OrderTypes.table">
      <div>Table no.</div>
      <div class="menu-header__info-content" kp-object="menu_header_expanded_table">{{ table }}</div>
    </section>
    <section>
      <div>Total bill</div>
      <div class="menu-header__info-content" kp-object="menu_header_expanded_total_bill">{{ cartPrice | currency : '£' }}</div>
    </section>
  </div>
</ion-header>

<!--Invisible element to take up required space-->
<div [ngStyle]="{ height: menuHeaderSizes.default + 'px' }"></div>

<!-- 'Patches up' gaps left by curved header shape -->
<div class="menu-header__backdrop"></div>
