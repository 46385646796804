import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ModalComponentModule } from '@libs/shared/ui';
import { ChangeRestaurantOptionsModalComponent } from './change-restaurant-options-modal.component';

@NgModule({
  imports: [CommonModule, ModalComponentModule, IonicModule],
  declarations: [ChangeRestaurantOptionsModalComponent],
  exports: [ChangeRestaurantOptionsModalComponent],
})
export class ChangeRestaurantOptionsModalModule {}
