import { Injectable } from '@angular/core';
import { SessionStorageService } from '@libs/shared/utilities';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, tap } from 'rxjs/operators';
import { MerchantWithMultiStoresSession } from '../../utilities/merchants-with-multi-stores.interface';
import { merchantWithMultipleStoreStorageKey } from '../../utilities/session-storage.constants';
import { merchantWithMultiStoresPageActions } from '../actions/merchant-with-multi-stores-page.actions';

@Injectable()
export class MerchantWithMultiStoresPageEffects {
  constructor(private actions$: Actions, private sessionStorageService: SessionStorageService) {}

  removeStoreIdSessionStorageKey$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(merchantWithMultiStoresPageActions.clearCart),
        switchMap(() => this.sessionStorageService.getItem<MerchantWithMultiStoresSession>(merchantWithMultipleStoreStorageKey)),
        tap((multiRestaurantSession) => {
          if (multiRestaurantSession) {
            this.sessionStorageService.removeItem(multiRestaurantSession.selectedRestaurantId);
          }
        })
      ),
    { dispatch: false } 
  );

  updateMultiStoreSessionStorage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(merchantWithMultiStoresPageActions.updateMultiStoreSession),
        map(({ merchantDetails }) => {
          this.sessionStorageService.setItem(merchantWithMultipleStoreStorageKey, merchantDetails);
        })
      ),
    { dispatch: false }
  );
}
