import { KodyOrderState, OrderTypes } from '@libs/shared/types';
import { updateItemGroup, updateItemGroupQuantity } from '@libs/shared/utilities';
import { createReducer, Action, on } from '@ngrx/store';
import { v4 } from 'uuid';
import { appStateApiActions } from '../actions/app-state-api.actions';
import { appStateActions } from '../actions/app-state.actions';

export const initialState: KodyOrderState = {
  currentCart: undefined,
  merchantStore: undefined,
  clickAndCollectSlot: undefined,
  availableClickAndCollectSlots: undefined,
  acknowledgedEvents: undefined,
};

const appStateReducerFn = createReducer(
  initialState,
  on(appStateApiActions.getMerchantStoreSuccess, (state, { merchantStore, orderType, table }) => ({
    ...state,
    merchantStore,
    currentCart: {
      orderType: orderType,
      restaurantTable: table,
      eatingIn: orderType === OrderTypes.table ? true : false,
      itemsMap: {},
      orderId: v4(),
    },
    table,
    serviceChargePercent: merchantStore.serviceCharge ? merchantStore.serviceChargeAmount : undefined,
  })),
  on(appStateActions.acknowledgeEvent, (state, { acknowledgedEvent }) => ({
    ...state,
    acknowledgedEvents: (state.acknowledgedEvents || []).concat(
      (state.acknowledgedEvents || []).includes(acknowledgedEvent) ? [] : acknowledgedEvent
    ),
  })),
  on(appStateActions.addItemToCart, (state, { item, addons, merchantStore, table }) => {
    const itemGroups = updateItemGroup(state.currentCart.itemGroups, item, addons);

    return {
      ...state,
      currentCart: {
        ...state.currentCart,
        itemGroups,
      },
    };
  }),
  on(appStateActions.updateCartItemQuantity, (state, { item, addons, quantity }) => {
    const itemGroups = updateItemGroupQuantity(state.currentCart?.itemGroups, item, addons, quantity);
    return {
      ...state,
      currentCart: {
        ...state.currentCart,
        itemGroups,
      },
    };
  }),
  on(appStateActions.updateCartFromStorage, (state, { cart, table, clickCollectSlot, discount, acknowledgedEvents }) => ({
    ...state,
    // ensuring state props are not cleared: session storage might not contain all props at this point
    currentCart: cart || state.currentCart,
    table: table || state.table,
    clickAndCollectSlot: clickCollectSlot || state.clickAndCollectSlot,
    discount: discount || state.discount,
    acknowledgedEvents: acknowledgedEvents || state.acknowledgedEvents || [],
  })),
  on(appStateActions.setEatingIn, (state, action) => ({
    ...state,
    currentCart: {
      ...state.currentCart,
      eatingIn: action.eatingIn,
    },
  })),
  on(appStateActions.setOrderNotes, (state, { notes }) => ({
    ...state,
    currentCart: {
      ...state.currentCart,
      notes,
    },
  })),
  on(appStateActions.setClickAndCollectSlot, (state, { slot }) => {
    return {
      ...state,
      clickAndCollectSlot: slot,
    };
  }),
  on(appStateActions.setDefaultClickAndCollectSlot, (state, { availableSlots }) => ({
    ...state,
    clickAndCollectSlot: availableSlots?.[0],
  })),
  on(appStateApiActions.getAvailableClickCollectSlotsSuccess, (state, { availableSlots }) => ({
    ...state,
    availableClickAndCollectSlots: availableSlots,
  })),
  on(appStateActions.setServiceCharge, (state, { serviceCharge }) => ({
    ...state,
    serviceChargeFixed: serviceCharge.fixed,
    serviceChargePercent: serviceCharge.percent,
  })),
  on(appStateActions.setDiscount, (state, { discount }) => ({
    ...state,
    discount,
  })),
  on(appStateActions.resetCartItems, (state: KodyOrderState) => ({
    ...state,
    currentCart: {
      ...state.currentCart,
      orderId: v4(),
      itemGroups: [],
    },
  })),
  on(appStateActions.checkoutError, (state: KodyOrderState) => ({
    ...state,
    currentCart: {
      ...state.currentCart,
      orderId: v4(),
    },
  })),
  on(appStateActions.updatePendingOrderId, (state: KodyOrderState) => ({
    ...state,
    currentCart: {
      ...state.currentCart,
      orderId: v4(),
    },
  })),
  on(appStateActions.clearState, () => initialState)
);

export function appStateReducer(state: KodyOrderState | undefined, action: Action): KodyOrderState {
  return appStateReducerFn(state, action);
}
