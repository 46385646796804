import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Cart } from '@libs/shared/types';
import { clearCartAction, returnAndPayAction } from '../../utilities/feature-menu.constants';

@Component({
  templateUrl: './change-restaurant-options-modal.component.html',
  styleUrls: ['./change-restaurant-options-modal.component.scss'],
})
export class ChangeRestaurantOptionsModalComponent {
  @Input() cart: Cart;
  @Input() restaurantName: string;

  noOfCartItemsToShow = 2;

  constructor(private modalController: ModalController) {}

  returnAndPay() {
    this.modalController.dismiss({ action: returnAndPayAction });
  }

  clearCart() {
    this.modalController.dismiss({ action: clearCartAction });
  }
}
