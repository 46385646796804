export const multiStoresMerchantStoreIdsProd = ['036a8066-5da1-4e98-af8b-aaaab570e286'];
export const multiStoresMerchantStoreIdsStage = ['2824771c-e3a7-4691-a042-b30077f22e60'];
export const multiStoresMerchantStoreIdsDev = ['036a8066-5da1-4e98-af8b-aaaab570e286'];

export const tokiaSquareRestaurantsProd = [
  { name: 'Japenese Unit', imageUrl: 'tokia-square/japanese-unit.png', merchantStoreId: '0671fbd0-c73c-4f8f-aa23-ad5996714b8e' },
  { name: 'Chinese Unit', imageUrl: 'tokia-square/chinese-unit.png', merchantStoreId: '188ac370-a158-4b63-8552-f226330607b8' },
  { name: 'Central Unit', imageUrl: 'tokia-square/central-unit.png', merchantStoreId: '623d4919-7baa-4f69-9d57-b3f7c88b88bd' },
  { name: 'Patisserie Unit', imageUrl: 'tokia-square/patisserie-unit.png', merchantStoreId: '0671fbd0-c73c-4f8f-aa23-ad5996714b8e' },
  { name: 'Singapore Unit', imageUrl: 'tokia-square/singapore-unit.png', merchantStoreId: '0671fbd0-c73c-4f8f-aa23-ad5996714b8e' },
  { name: 'Korean Unit', imageUrl: 'tokia-square/korean-unit.png', merchantStoreId: '0671fbd0-c73c-4f8f-aa23-ad5996714b8e' },
  { name: 'Indian Unit', imageUrl: 'tokia-square/indian-unit.png', merchantStoreId: '0671fbd0-c73c-4f8f-aa23-ad5996714b8e' },
  { name: 'Thai Unit', imageUrl: 'tokia-square/thai-unit.png', merchantStoreId: '0671fbd0-c73c-4f8f-aa23-ad5996714b8e' },
  { name: 'Vietnamese Unit', imageUrl: 'tokia-square/vietnamese-unit.png', merchantStoreId: '0671fbd0-c73c-4f8f-aa23-ad5996714b8e' },
];

export const tokiaSquareRestaurantsStage = [
  { name: 'Central Production', imageUrl: 'tokia-square/central-unit.png', merchantStoreId: '7761b438-fad8-4e1b-84a1-c9d3b2b53539' },
  { name: 'Shikumen Express', imageUrl: 'tokia-square/chinese-unit.png', merchantStoreId: 'c95397d7-67a6-493d-9eb0-bc9fb2db0f6f' },
  { name: 'Idimsum', imageUrl: 'tokia-square/dimsum.png', merchantStoreId: 'ae2aeaf0-624b-4217-a4c1-d6844529857e' },
  { name: 'Thai Unit', imageUrl: 'tokia-square/thai-unit.png', merchantStoreId: '891caff2-c1b5-462b-b5f6-a2a81bcf2545' },
  { name: 'Japanese Unit', imageUrl: 'tokia-square/japanese-unit.png', merchantStoreId: '3747dd50-14ce-49cb-b86e-97dae0253227' },
  { name: 'Indian Unit', imageUrl: 'tokia-square/indian-unit.png', merchantStoreId: 'afdfc092-95e2-4833-a138-ac16f5c5cc8a' },
  { name: 'Singapore Unit', imageUrl: 'tokia-square/singapore-unit.png', merchantStoreId: '4817195b-7e4d-47c7-94d6-b7a135680cde' },
  { name: 'Korean Unit', imageUrl: 'tokia-square/korean-unit.png', merchantStoreId: 'ca525128-b098-4e2a-91df-73308251f99a' },
  { name: 'Vietnamese Unit', imageUrl: 'tokia-square/vietnamese-unit.png', merchantStoreId: 'fce53dbe-dbbd-4d3f-89ae-6eb0570ec313' },
  { name: 'Bubble Tea', imageUrl: 'tokia-square/bubble-tea.png', merchantStoreId: '68230da7-b7e6-48d3-a528-907a8a286ba6' },
  { name: 'Wine & Beer', imageUrl: 'tokia-square/wine-beer.png', merchantStoreId: '11fac3ae-df63-40c0-8a24-431a0b5ee98b' },
];

export const tokiaSquareRestaurantsDev = [
  { name: 'Japenese Unit', imageUrl: 'tokia-square/japanese-unit.png', merchantStoreId: '0671fbd0-c73c-4f8f-aa23-ad5996714b8e' },
  { name: 'Chinese Unit', imageUrl: 'tokia-square/chinese-unit.png', merchantStoreId: '188ac370-a158-4b63-8552-f226330607b8' },
  { name: 'Central Unit', imageUrl: 'tokia-square/central-unit.png', merchantStoreId: '623d4919-7baa-4f69-9d57-b3f7c88b88bd' },
  { name: 'Patisserie Unit', imageUrl: 'tokia-square/patisserie-unit.png', merchantStoreId: '0671fbd0-c73c-4f8f-aa23-ad5996714b8e' },
  { name: 'Singapore Unit', imageUrl: 'tokia-square/singapore-unit.png', merchantStoreId: '0671fbd0-c73c-4f8f-aa23-ad5996714b8e' },
  { name: 'Korean Unit', imageUrl: 'tokia-square/korean-unit.png', merchantStoreId: '0671fbd0-c73c-4f8f-aa23-ad5996714b8e' },
  { name: 'Indian Unit', imageUrl: 'tokia-square/indian-unit.png', merchantStoreId: '0671fbd0-c73c-4f8f-aa23-ad5996714b8e' },
  { name: 'Thai Unit', imageUrl: 'tokia-square/thai-unit.png', merchantStoreId: '0671fbd0-c73c-4f8f-aa23-ad5996714b8e' },
  { name: 'Vietnamese Unit', imageUrl: 'tokia-square/vietnamese-unit.png', merchantStoreId: '0671fbd0-c73c-4f8f-aa23-ad5996714b8e' },
];
