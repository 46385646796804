import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { NavController } from '@ionic/angular';
import { appStateActions, AppStateFacadeService } from '@libs/app-kody-order/utility-app-state';
import { Environment, kodyOrderBaseRoutes, kodyOrderRoutes, OrderTypes } from '@libs/shared/types';
import { APP_ENVIRONMENT } from '@libs/shared/utilities';
import { FeatureFlagFacadeService, featureFlags } from '@libs/utility-feature-flags';
import { take } from 'rxjs/operators';
const { multiStores } = kodyOrderBaseRoutes;

@Injectable({ providedIn: 'root' })
export class AppRoutingGuard implements CanActivate {
  constructor(
    private appStateFacadeService: AppStateFacadeService,
    @Inject(DOCUMENT) private document: Document,
    private navController: NavController,
    private featureFlagFacadeService: FeatureFlagFacadeService,
    @Inject(APP_ENVIRONMENT) private environment: Environment

  ) {}

  canActivate = async (routeSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const url = new URL(this.document.defaultView.location.href);
    const urlSegments = url.pathname.split('/');
    // if the url is a legacy route then do nothing and let the legacy route guard handle the redirection to a valid route
    if (urlSegments[1] !== kodyOrderBaseRoutes.root) {
      return true;
    }
    // using the Merchant store Id and Current route based on angular router params as ionic navigation doesnt update url so
    // current route and merchant id are used from router
    const merchantStoreId: string = routeSnapshot.params['storeId'];
    const currentRoute = this.getLastRouteSegment(state.url);

    let orderType: OrderTypes;
    let table: string;
    switch (currentRoute) {
      case 'table':
      case 'restaurants':
        table = routeSnapshot.queryParams['table'];
        orderType = OrderTypes.table;
        break;
      case 'counter':
        orderType = OrderTypes.counter;
        break;
      case 'click-collect':
        orderType = OrderTypes.clickCollect;
        break;
      default:
        orderType = OrderTypes.counter;
        break;
    }

    const showTablePrompt = routeSnapshot.queryParams['promptTable'];
    if (showTablePrompt) {
      table = undefined; // we have to remove the table as the qr code may have a table number set
      while (!table) {
        // Keep prompting for a table number until they enter a non-blank string
        table = this.document.defaultView.prompt('Please enter your table number').trim();
      }
      this.appStateFacadeService.dispatch(
        appStateActions.enterApp({
          merchantStoreId,
          orderType: OrderTypes.table,
          table,
        })
      );
    } else {
      this.appStateFacadeService.dispatch(appStateActions.enterApp({ merchantStoreId, orderType, table }));
    }

    const multiStoreFeatureFlagEnabled = await this.featureFlagFacadeService.isActive(featureFlags.multiStore).pipe(take(1)).toPromise();

    // tokia square navigate to multi-stores page
    if (multiStoreFeatureFlagEnabled && currentRoute !== multiStores && this.environment.multiStoreMerchantIds.includes(merchantStoreId)) {
      this.navController.navigateRoot(kodyOrderRoutes.multiStores(merchantStoreId));
      return false;
    }
    return true;
  };

  private getLastRouteSegment(url: string): string {
    // Split the URL into segments and return the last segment
    const segments = url.split('/');
    const currentRoute = segments[segments.length - 1];
    return currentRoute.split('?')[0];
  }
}
