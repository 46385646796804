import { CreateRestaurantOrderItemRequest } from './create-restaurant-order-item-request.interface';
import { OrderTypes } from '../../enums/order-types.enum';

export interface CreateOrder {
  orderId: string;
  merchantStoreId: string;
  tableNumber: string;
  items: CreateRestaurantOrderItemRequest[];
  orderType: OrderTypes;
  totalAmount: string;
  eatIn: boolean;
  serviceChargeFixed: number;
  serviceChargePercent: number;
  notes: string;
  timeSlotId?: string;
  currencyCode: string;
  returnUrl: string;
  kodyPayment: boolean; // This is added now for future work when we spit the ordering and the payment part from KodyOrder so will always be true for now
  emailAddress: string;
  customerName: string;
}
